import { useState, useEffect, useLayoutEffect } from 'react'
import { fetcher } from '../../classes/Fetcher'
import { API_DOMAIN } from '../../constants'
import { getPersistentStore } from '../../PersistentStore'
import { Course } from '../../types/courseTypes'
import { useUserCourses } from './useUserCourses'

export const useCourse = (courseID?: string, cachable: boolean = true) => {
  const userCourses = useUserCourses()
  console.log('useCourse:', courseID, userCourses)
  const [fetchCourse, setFetchCourse] = useState(userCourses?.get('_all')?.get(courseID!) as null | undefined | Course)
  useLayoutEffect(() => {
    let cancel = false
    if (!courseID) return
    cachable &&
      getPersistentStore.then(async store => {
        const course = await store.getItem(`course.${courseID}`)
        course && !cancel && setFetchCourse(c => c || (course as Course))
      })
    fetcher(`${API_DOMAIN}/courses/${courseID}`)
      .then(x => (x.ok ? x.json() : null) as Promise<Course | null>)
      .then(course => {
        if (course) {
          const userCourse = userCourses?.get(course.communityID)
          if (userCourse) {
            userCourse.set(courseID, course)
          }
          !cancel && setFetchCourse(course)
          getPersistentStore.then(async store => {
            await store.setItem(`course.${courseID}`, course)
          })
        } else {
          !cancel &&
            setFetchCourse({
              name: 'Unavailable Course',
              teacher: 'Unavailable',
              _id: 'unavailable',
              id: courseID,
              communityID: 'unavailable',
              icon: '',
              integrationType: 'unavailable',
              integrationSnowflake: 'unavailable',
              members: [],
              period: 'unavailable',
              membersObjects: [],
            })
        }
      })
    return () => {
      cancel = true
    }
  }, [courseID, cachable, userCourses])
  if (!courseID || !fetchCourse) return null
  return fetchCourse || userCourses?.get(courseID)
}
