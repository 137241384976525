import { useEffect, useState } from 'react'
import { ScheduleClass } from '../../classes/ScheduleClass'
import { GenericMasterSchedule } from '../../types/scheduleTypes'

export const useMasterSchedule = (communityID?: string) => {
  const [schedule, setSchedule] = useState(null as GenericMasterSchedule | null)
  useEffect(() => {
    if (!communityID) return
    const scheduleClass = ScheduleClass.getInstance()
    const update = () => {
      const schedule = scheduleClass.masterSchedules.get(communityID)
      
      schedule && setSchedule(schedule)
    }
    scheduleClass.addListener('masterScheduleUpdate', update)
    update()
    return () => {
      scheduleClass.removeListener('masterScheduleUpdate', update)
    }
  }, [communityID])
  return schedule
}
