import { useEffect, useLayoutEffect, useState } from 'react'
import { UserCommunities } from '../../classes/UserCommunities'
import useForceUpdate from '../../useForceUpdate'
import { useCurrentUser } from '../self/useSelfInfo'
import { useUserCommunities } from './useUserCommunities'
import { useUserCourses } from '../course/useUserCourses'
import { Course } from '../../types/courseTypes'

export const useCommunityCourses = (communityID?: string) => {
  const userCourses = useUserCourses()
  const [courses, setCourses] = useState(null as null | Map<string, Course>)
  useEffect(() => {
    if (!communityID) return
    setCourses(userCourses?.get(communityID)!)
    // alert('community courses changed')
    return () => {
      // setCourses(null)
    }
  }, [userCourses, communityID])
  return courses
}
